<template>
  <div class="app-container">
    <div class="page-title">
      <h2>{{ $t("site.sites") }}</h2>
    </div>
    <el-card
      v-if="checkRole(['Admin'])"
      class="box-card filter-card just-for-admin"
    >
      <div slot="header" class="clearfix">
        <span>{{ $t("general.filter") }}</span>
        <el-button
          style="float: right; padding: 3px 0"
          type="text"
          @click="initSearch"
        >{{ $t("general.init") }}</el-button>
      </div>
      <div class="filter-container">
        <el-radio
          v-model="activeList"
          label="actives"
          border
          @change="getSubSitesList('indexactive')"
        >{{ $t("general.F_actives") }}</el-radio>
        <el-radio
          v-model="activeList"
          label="desactives"
          border
          @change="getSubSitesList('indexdesactive')"
        >{{ $t("general.F_desactives") }}</el-radio>
        <el-radio
          v-model="activeList"
          label="disponibles"
          border
          @change="handleFilterSites"
        >{{ $t("general.disponibles") }}</el-radio>
        <el-radio
          v-model="activeList"
          label="corbeille"
          class="danger-radio"
          border
          @change="getSubSitesList('indexinvisible')"
        >{{ $t("general.corbeille") }}</el-radio>
      </div>
    </el-card>

    <el-card class="box-card">
      <div class="filter-container">
        <el-input
          v-model="query.keyword"
          clearable
          :placeholder="$t('general.keyword')"
          class="wd-200 mr-15 filter-item"
          @keyup.enter.native="handleFilterSites"
        />
        <el-button
          v-waves
          class="mr-15 filter-item"
          type="primary"
          icon="el-icon-search"
          @click="handleFilterSites"
        >
          {{ $t("general.search") }}
        </el-button>
        <el-button
          v-waves
          :loading="downloading"
          class="filter-item"
          type="primary"
          icon="el-icon-download"
          @click="handleDownload"
        >
          {{ $t("general.export") }}
        </el-button>
        <el-button v-if="checkPermission(['create site'])" class="mr-15 filter-item" type="success" icon="el-icon-plus" @click="handleCreateSite">
          {{ $t('general.add') }}
        </el-button>
      </div>
      <el-table
        v-loading="loading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column align="center" :label="$t('site.url')" min-width="300px">
          <template slot-scope="scope">
            <router-link :to="'/sites/pages/'+scope.row.id" class="link-type">
              <div class="full-width">
                <div class="half-photo">
                  <div class="demo-basic--circle">
                    <div>
                      <el-avatar shape="square" :size="50" :src="scope.row.capture" />
                    </div>
                  </div>
                </div>
                <div class="half-right">
                  <div class="in-td">
                    <h4>{{ scope.row.url }}</h4>
                    <small>{{ scope.row.title }}</small>
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('site.pagecount')"
          width="100"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.page_count }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          :label="$t('site.adminEmail')"
          width="250"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.admin_email }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="activeList!='corbeille' && checkPermission(['activate site'])" align="center" :label="$t('general.status')" width="100">
          <template slot-scope="scope">
            <el-switch
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              :value="scope.row.is_active"
              :disabled="!scope.row.is_visible"
              @change="setSiteStatus(scope.row.id,'active')"
            />
          </template>
        </el-table-column>

        <el-table-column
          v-if="
            checkRole(['Admin']) && (activeList == 'corbeille' || query.keyword)
          "
          align="center"
          :label="$t('general.restaurer')"
          width="100"
        >
          <template slot-scope="scope">
            <el-switch
              v-if="!scope.row.is_visible"
              style="display: block"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="true"
              :inactive-value="false"
              :value="scope.row.is_visible"
              @change="setSiteStatus(scope.row.id, 'visible')"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="checkPermission(['edit site', 'hide site', 'delete site'])"
          align="center"
          :label="
            1 == 1 ? $t('general.actions') : $t('general.suppressionDefinitive')
          "
          width="150"
        >
          <template slot-scope="scope">
            <router-link :to="'/sites/pages/'+scope.row.id" class="link-type">
              <el-button
                v-if="scope.row.is_visible && checkPermission(['edit site'])"
                type="primary"
                size="small"
                icon="el-icon-view"
                style="margin-right: 10px"
              />
            </router-link>
            <el-button
              v-if="checkPermission(['hide site', 'delete site'])"
              slot="reference"
              type="danger"
              size="small"
              icon="el-icon-delete"
              style="margin-right: 10px"
              @click="
                handleDeleteSite(
                  scope.row.id,
                  scope.row.is_visible,
                  scope.row.url
                )
              "
            />
          </template>
        </el-table-column>
      </el-table>

      <pagination
        v-if="total > 0"
        :total="total"
        :page.sync="query.page"
        :limit.sync="query.limit"
        @pagination="getSitesList"
      />

      <el-dialog
        :title="editing ? $t('site.edit') + ' ' + currentSite.url : $t('site.AddSite')"
        :visible.sync="dialogCreateSite"
        :before-close="handleCloseSiteCreateDraw"
        :loading="siteCreating"
        width="70%"
      >
        <div class="content-inputs">
          <el-form ref="siteForm" :rules="rules" :model="currentSite" label-position="left">
            <el-form-item :label="$t('site.url')" prop="url">
              <el-input v-model="currentSite.url" />
            </el-form-item>
            <!-- <el-alert
              v-show="linkchecked"
              :title="steps.errorTitle"
              type="error"
              :description="steps.errorMessage"
              show-icon
            /> -->
            <el-form-item :label="$t('site.adminEmail')" prop="admin_email">
              <el-input v-model="currentSite.admin_email" />
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <div style="text-align:right;">
            <el-button plain type="danger" @click="dialogCreateSite=false">
              {{ $t('permission.cancel') }}
            </el-button>
            <el-button type="primary" :loading="siteCreating" @click="editing ? updateSite() : createSite()">
              {{ siteCreating ? $t('general.saving') : $t('general.save') }}
            </el-button>
          </div>
        </span>
      </el-dialog>

      <!-- ======= SHOW SITE DIALOG ======= -->
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'; // Pagination
import Resource from '@/api/resource';
import waves from '@/directive/waves'; // Directives
import permission from '@/directive/permission'; //  directive Permission
import role from '@/directive/role'; // Directives Role
import checkPermission from '@/utils/permission'; // Vérification Permission
import checkRole from '@/utils/role'; // Vérification Roles
import moment from 'moment';
const siteResource = new Resource('sites');
export default {
  name: 'SitesList',
  components: { Pagination },
  directives: { waves, permission, role },
  data() {
    return {
      extract: 0,
      list: null,
      total: 0,
      loading: false,
      downloading: false,
      siteCreating: false,
      editing: false,
      activeList: 'disponibles',
      processings: this.$processingStatus,
      query: {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      },
      currentSite: {},
      dialogCreateSite: false,
      currentSiteId: 0,
      rules: {
        url: [
          {
            required: true,
            message: this.$t('site.UrlRequired'),
            trigger: 'blur',
          },
        ],
        admin_email: [
          {
            type: 'email',
            message: this.$t('site.validAdminEmail'),
            trigger: ['blur', 'change'],
          },
        ],
      },
    };
  },
  computed: {},
  created() {
    this.resetNewSite();
    this.getSitesList();
  },
  methods: {
    checkPermission,
    checkRole,
    isEmptyArray(array) {
      if (typeof array !== 'undefined' && array.length === 0) {
        return true;
      }
    },
    handleCloseSiteCreateDraw(done) {
      this.$confirm(this.$t('general.closingWarningText'))
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    cancelSiteForm() {
      this.$refs.SiteCreationDrawer.closeDrawer();
    },
    getprocessingColor(processing) {
      var color = 'primary';
      if (processing === 'waiting') {
        color = 'warning';
      } else if (processing === 'finished') {
        color = 'success';
      } else if (processing === 'canceled') {
        color = 'info';
      }
      return color;
    },
    initSearch() {
      this.query = {
        page: 1,
        limit: this.$limit,
        keyword: '',
        processing: 'waiting',
      };
      this.activeList = 'disponibles';
      this.handleFilterSites();
    },
    async getSitesList() {
      this.loading = true;
      const { limit, page } = this.query;
      const { data, meta } = await siteResource.list(this.query);
      this.list = data;
      this.list.forEach((element, index) => {
        element['index'] = (page - 1) * limit + index + 1;
      });
      this.total = meta.total;
      this.loading = false;
    },
    async getSubSitesList(subUrl) {
      this.loading = true;
      const { data } = await siteResource.sublist('/' + subUrl, this.query);
      this.list = data;
      this.loading = false;
    },
    handleFilterSites() {
      this.query.page = 1;
      this.getSitesList();
    },
    handleCreateSite() {
      this.editing = false;
      this.resetNewSite();
      this.dialogCreateSite = true;
      this.$nextTick(() => {
        this.$refs['siteForm'].clearValidate();
      });
    },
    handleEditSite(id) {
      this.editing = true;
      this.resetNewSite();
      this.currentSite = this.list.find((site) => site.id === id);
      this.dialogCreateSite = true;
      this.$nextTick(() => {
        this.$refs['siteForm'].clearValidate();
      });
    },
    async setSiteStatus(id, type) {
      const data = await siteResource.get('toogle_' + type + '/' + id);
      if (data.success) {
        this.$message({
          type: 'success',
          message: this.$t('general.statusUpdated'),
        });
        this.initSearch();
      } else {
        this.$message({
          type: 'danger',
          message: this.$t('general.statusUpdateError'),
        });
      }
    },
    handleDeleteSite(id, visible, name) {
      this.$confirm(
        this.$t('site.deleteWarn') +
          ' ' +
          name +
          '. ' +
          this.$t('general.deleteContinue'),
        'Warning',
        {
          confirmButtonText: 'Yes',
          cancelButtonText: this.$t('general.cancel'),
          type: 'warning',
        }
      )
        .then(() => {
          if (
            !visible &&
            (checkRole(['Admin']) || checkPermission(['delete site']))
          ) {
            this.$swal({
              title: this.$t('general.SureQuestion'),
              text: this.$t('general.irrversibleMessage'),
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.$t('general.ouiSupprimer'),
            }).then((result) => {
              if (result.isConfirmed) {
                siteResource
                  .destroy(id)
                  .then((response) => {
                    this.$message({
                      type: 'success',
                      message: this.$t('site.suppressionEffectue'),
                    });
                    this.initSearch();
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            });
          } else {
            this.setSiteStatus(id, 'visible');
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: this.$t('general.suppressionAnnule'),
          });
        });
    },
    createSite() {
      this.$refs['siteForm'].validate((valid) => {
        if (valid) {
          this.siteCreating = true;
          siteResource.store(this.currentSite)
            .then(async(response) => {
              this.$message({
                message:
                  this.$t('site.NewSite') +
                  ' ' +
                  this.currentSite.url +
                  ' ' +
                  this.$t('site.iscreatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.currentSiteId = response.data.id;
              // Lunch extraction and go to site single page with extracting notif
              this.extractPages(this.currentSiteId);
              this.handleFilterSites();
              this.siteCreating = false;
              this.dialogCreateSite = false;
              const urlToGo = '/sites/pages/' + this.currentSiteId + '?ext=' + this.extract;
              setTimeout(function() {
                window.location.href = urlToGo;
              }, 5000);
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              //               this.$router.go();
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    updateSite() {
      this.$refs['siteForm'].validate((valid) => {
        if (valid) {
          this.siteCreating = true;
          siteResource
            .update(this.currentSite.id, this.currentSite)
            .then((response) => {
              this.resetNewSite();
              this.handleFilterSites();
              this.$message({
                message: this.$t('site.isupdatedSuccessfully'),
                type: 'success',
                duration: 5 * 1000,
              });
              this.dialogCreateSite = false;
              this.editing = false;
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              this.siteCreating = false;
            });
        } else {
          this.$message({
            type: 'danger',
            message: this.$t('general.invalidForm'),
          });
          return false;
        }
      });
    },
    extractPages(id) {
      this.extract = 1;
      siteResource.put(id, 'extract_pages', {});
    },
    resetNewSite() {
      this.currentSite = {
        url: '',
        admin_email: '',
      };
    },
    handleDownload() {
      this.downloading = true;
      import('@/vendor/Export2Excel').then((excel) => {
        const tHeader = [
          'ID',
          this.$t('site.url'),
          this.$t('site.title'),
          this.$t('report.processing'),
        ];
        const filterVal = ['id', 'url', 'title', 'processing'];
        const data = this.formatJson(filterVal, this.list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename:
            'Sites list DEFACE TRACKER_' + moment().format('DD-MM-YYYY_hh-mm'),
        });
        this.downloading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
  },
};
</script>

<style lang="scss" scoped>
.half-right {
  width: 100% !important;
  float: none !important;
  text-align: left !important;
  padding: 2px;
}
.edit-input {
  padding-right: 100px;
}
.cancel-btn {
  position: absolute;
  right: 15px;
  top: 10px;
}
.dialog-footer {
  text-align: left;
  padding-top: 0;
  margin-left: 150px;
}
.app-container {
  flex: 1;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
  .block {
    float: left;
    min-width: 250px;
  }
  .clear-left {
    clear: left;
  }
}
</style>
